import React from "react";
import Devices from "../components/Devices";
import DevicesBar from "../components/DevicesBar";
import "../components/Devices.css";

function DevicesScreen() {
  return (
    <div className="devices_Screen">
      <DevicesBar />
      <Devices />
    </div>
  );
}

export default DevicesScreen;
