/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./DevicesList.module.css";
import { Link } from "react-router-dom";

const URL_DM = process.env.REACT_APP_URL_DM;

function DevicesList() {
  const [devices, setDevices] = useState([]);
  const [activeUserId, setActiveUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [devicesPerPage] = useState(5);

  useEffect(() => {
    const fetchActiveUserId = async () => {
      try {
        const userData = JSON.parse(sessionStorage.getItem("user"));
        if (userData) {
          setActiveUserId(userData.id_user);
        }
      } catch (error) {
        console.error("Error fetching active user ID:", error);
      }
    };

    fetchActiveUserId();
  }, []);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        if (activeUserId) {
          setLoading(true);
          let url = `${URL_DM}/api/get_devices/${activeUserId}`;

          if (filterStatus !== "all") {
            url += `?status=${filterStatus}`;
          }

          const response = await axios.get(url);
          const sortedDevices = sortDevices(response.data);
          setDevices(sortedDevices);
        }
      } catch (error) {
        console.error("Error fetching devices:", error);
        setErrorMessage("Failed to fetch devices.");
      } finally {
        setLoading(false);
      }
    };

    fetchDevices();
  }, [activeUserId, filterStatus]);

  const sortDevices = (devices) => {
    const onlineDevices = devices.filter((device) => device.status === "online");
    const offlineDevices = devices.filter((device) => device.status !== "online");
    return [...onlineDevices, ...offlineDevices];
  };

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const currentDevices = devices.slice(indexOfFirstDevice, indexOfLastDevice);

  return (
    <div className={styles.card_devices}>
      <p>Últimos dispositivos cadastrados</p>
      <table className={styles.devices_Table}>
        <thead>
          <tr>
            <th>Nome do dispositivo</th>
            <th>Modelo</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {currentDevices.map((device) => (
            <tr key={device.id_device}>
              <td>{device.device_name}</td>
              <td>{device.device_model}</td>
              <td className={device.status === "online" ? styles.status_online : styles.status_offline}>
                {device.status === "online" ? (
                  <span className={styles.online_text}>• Online</span>
                ) : device.status === "streaming" ? (
                  <span className={styles.streaming_text}>Streaming</span>
                ) : (
                  <span className={styles.offline_text}>• Offline</span>
                )}
              </td>
            </tr>
          ))}
          {Array.from({ length: Math.max(0, 5 - currentDevices.length) }).map((_, index) => (
            <tr key={`empty-${index}`}>
              <td className={styles.empty_device}>-</td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.buttonContainer}>
        <Link to="/devices" className={styles.data_Button}>
          Ver todos
        </Link>
      </div>
    </div>
  );
}

export default DevicesList;
