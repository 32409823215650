import React from "react";
import Profile from "../components/Profile";

function SettingsScreen() {
  return (
    <div className="settings">
      <Profile />
    </div>
  );
}

export default SettingsScreen;
