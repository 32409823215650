import React from "react";
import styles from "./PlanCard.module.css";

const PlanCard = () => {
  return (
    <div className={styles.card}>
      <h2 className={styles.title}>Plano Enterprise</h2>
      <div className={styles.status}>ATIVO</div>
      <p className={styles.expiry}>Vencimento: 14 dias Trial</p>
      <p className={styles.paymentType}>Forma de pagamento: Gratuito</p>
      <div className={styles.buttonContainer}>
        <button className={styles.button}>Alterar Plano</button>
      </div>
    </div>
  );
};

export default PlanCard;
