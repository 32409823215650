import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const GrupoMonteCarlo = () => {
  const [mockedData1, setMockedData1] = useState(0);
  const [mockedData2, setMockedData2] = useState(0);
  const [mockedData3, setMockedData3] = useState(0);
  const [mockedData4, setMockedData4] = useState(0);
  const [mockedData5, setMockedData5] = useState(0);
  const [mockedData6, setMockedData6] = useState(0);

  const generateRandomMockedData = () => {
    setMockedData1(Math.floor(Math.random() * 20) + 1);
    setMockedData2(Math.floor(Math.random() * 10) + 1);
    setMockedData3(Math.floor(Math.random() * 40) + 1);
    setMockedData4(Math.floor(Math.random() * 100) + 1);
    setMockedData5(Math.floor(Math.random() * 5) + 1);
    setMockedData6(Math.floor(Math.random() * 6) + 1);
  };

  useEffect(() => {
    generateRandomMockedData();
  }, []);
  // Dados de exemplo para as categorias
  const data = {
    labels: ["Pessoa", "Pedestre", "Animal", "Carro", "Fogo", "Fumaça"],
    datasets: [
      {
        label: "Categorias",
        data: [
          mockedData1,
          mockedData2,
          mockedData3,
          mockedData4,
          mockedData5,
          mockedData6,
        ], // Mock data de 0 a 100
        backgroundColor: [
          "rgba(54, 162, 235, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(255, 99, 132, 0.6)",
          "rgba(125, 125, 125, 1)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(125, 125, 125, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true, // Mantém a proporção do gráfico
    plugins: {
      legend: {
        position: "right",
        labels: {
          padding: 15, // Distância entre o gráfico e as labels
        },
      },
      title: {
        display: false,
        text: "Distribuição de Categorias",
      },
    },
  };

  return (
    <div
      style={{
        width: "400px", // Defina a largura desejada
        height: "400px", // Defina a altura desejada
        margin: "auto", // Centraliza o gráfico
        position: "relative", // Para evitar interferência de outros elementos
        backgroundColor: "transparent", // Fundo transparente
      }}
    >
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default GrupoMonteCarlo;
