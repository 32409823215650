import styles from "./Sofia.module.css";

const Sofia = () => {
  return (
    <div className={styles.container}>
      <div className={styles.containersofia}>
        <div className={styles.view}>
          <div className={`${styles.plane} ${styles.main}`}>
            <div className={styles.circle}></div>
            <div className={styles.circle}></div>
            <div className={styles.circle}></div>
            <div className={styles.circle}></div>
            <div className={styles.circle}></div>
            <div className={styles.circle}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sofia;
