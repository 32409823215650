/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "../components/Profile.css";

const URL_DM = process.env.REACT_APP_URL_DM;

function Profile() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [id_user, setIdUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [initialProfileImage, setInitialProfileImage] = useState(null);

  useEffect(() => {
    // Função para buscar informações do usuário na sessão
    const fetchUserDataFromSession = () => {
      try {
        const userData = JSON.parse(sessionStorage.getItem("user"));
        if (userData && userData.id_user) {
          // Definir os estados com os dados do usuário da sessão
          setIdUser(userData.id_user);
          setEmail(userData.email);
          setFirstName(userData.user_name);
          setLastName(""); // Ajuste conforme seus dados de usuário
          setInitialProfileImage(userData.user_image); // URL da imagem do perfil
        } else {
          console.error("Dados do usuário não encontrados na sessão.");
        }
      } catch (error) {
        console.error("Erro ao buscar informações do usuário na sessão:", error);
      }
    };

    fetchUserDataFromSession();
  }, []); // Executar apenas uma vez, após a montagem do componente

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleImageChange = (event) => {
    setProfileImage(event.target.files[0]);
    setShowModal(false); // Fechar modal após selecionar a imagem
  };

  const handleCancel = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setProfileImage(null);
  };

  const handleSave = async () => {
    if (!profileImage) {
      alert("Por favor, selecione uma imagem para o perfil.");
      return;
    }

    if (!id_user) {
      alert("ID do usuário não encontrado.");
      return;
    }

    const formData = new FormData();
    formData.append("image", profileImage);

    try {
      const response = await fetch(`${URL_DM}/api/update_user_image/${id_user}`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        alert("Foto do usuário atualizada com sucesso");
      } else {
        alert(`Erro: ${data.response}`);
      }
    } catch (error) {
      console.error("Erro ao enviar a imagem:", error);
      alert("Ocorreu um erro ao atualizar a imagem do perfil.");
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="profile">
      <div className="profile_Content">
        <div className="profile_Header">
          <div className="profile_Info">
            <h2>Personal Info</h2>
            <p>Update your photo and personal details here.</p>
          </div>
          <div className="profile_Buttons">
            <button className="profile_Cancel" onClick={handleCancel}>
              Cancel
            </button>
            <button className="profile_Save" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
        <div className="profile_Pic">
          <p>Your photo</p>
          <div className="profile_Photo" onClick={openModal}>
            {profileImage ? (
              <img src={URL.createObjectURL(profileImage)} alt="Profile" />
            ) : (
              <img src={initialProfileImage} alt="Profile" />
            )}
          </div>
        </div>
        <div className="profile_Name">
          <p>Name</p>
          <div className="profile_Inputs">
            <input type="text" placeholder="First Name" value={firstName} onChange={handleFirstNameChange} />
          </div>
        </div>

        <div className="profile_Email">
          <p>Email address</p>
          <div className="profile_Inputs">
            <input type="text" placeholder="Email" value={email} onChange={handleEmailChange} />
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <p>Select a new profile image</p>
            <input type="file" accept="image/*" onChange={handleImageChange} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
