import { useState } from "react";
import Conveniencia from "./Reports/Conveniencia";
import PisoDeVenda from "./Reports/PisoDeVenda";

const Reports = () => {
  const [selectedGroup, setSelectedGroup] = useState("Grupo0");

  const handleChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const Grupo0 = () => (
    <div>
      <Conveniencia />
    </div>
  );
  const Grupo1 = () => (
    <div>
      <PisoDeVenda />
    </div>
  );

  const renderComponent = () => {
    switch (selectedGroup) {
      case "Grupo0":
        return <Grupo0 />;
      case "Grupo1":
        return <Grupo1 />;
      default:
        return <Grupo0 />;
    }
  };

  return (
    <div className="reports_container">
      <div className="reports_header">
        <div className="reports_margin">
          <h1>Relatórios</h1>
        </div>

        <div className="reports_select">
          <select value={selectedGroup} onChange={handleChange} name="selected_group">
            <option value="Grupo0">Conveniência</option>
            <option value="Grupo1">Piso de Venda</option>
          </select>
        </div>
      </div>
      <div className="reports_body">{renderComponent()}</div>
    </div>
  );
};

export default Reports;
