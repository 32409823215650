import { HeatMapGrid } from "react-grid-heatmap";

const xLabels = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"];
const yLabels = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];
const data = new Array(yLabels.length)
  .fill(0)
  .map(() =>
    new Array(xLabels.length)
      .fill(0)
      .map(() => Math.floor(Math.random() * 16 + 0))
  );

const HeatMapTempoCelular = () => {
  return (
    <HeatMapGrid
      data={data}
      xLabels={xLabels}
      yLabels={yLabels}
      cellRender={(x, y, value) => (
        <div title={`Pos(${x}, ${y}) = ${value}`}>{value}</div>
      )}
      xLabelsStyle={(index) => ({
        color: '#fff',
        fontSize: '.8rem',
      })}
      yLabelsStyle={() => ({
        fontSize: '.7rem',
        textTransform: 'uppercase',
        margin: '5px'
      })}
      cellHeight={"2rem"}
      cellStyle={(_x, _y, ratio) => ({
        background: `rgb(200, 0, 139, ${ratio})`,
        fontSize: '.8rem',
        fontWeight: 'bold',
        color: `rgb(255, 255, 255, ${ratio / 2 + 0.4})`,
        border: `solid #1c1c1c`,
        margin: `5px`,
      })}
    />
  );
};

export default HeatMapTempoCelular;
