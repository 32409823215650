import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MultiAxis = () => {
  const data = {
    labels: ["Pessoas", "Animais", "Carros", "Motos", "Caminhões", "Celular", "Armas", "Fumaça", "Fogo"],
    datasets: [
      {
        
        data: [25, 7, 19, 6, 9, 4, 1, 4, 2],
        backgroundColor: [
            "rgba(54, 162, 235, 0.6)",
            "rgba(75, 192, 192, 0.6)",
            "rgba(153, 102, 255, 0.6)",
            "rgba(255, 159, 64, 0.6)",
            "rgba(255, 99, 132, 0.6)",
            "rgba(125, 125, 125, 0.6)",
            "rgba(200, 100, 100, 0.6)",
            "rgba(100, 200, 100, 0.6)",
            "rgba(100, 100, 200, 0.6)",
          ],
          borderColor: [
            "rgba(54, 162, 235, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(255, 99, 132, 1)",
            "rgba(125, 125, 125, 1)",
            "rgba(200, 100, 100, 1)",
            "rgba(100, 200, 100, 1)",
            "rgba(100, 100, 200, 1)",
          ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Gráfico de Barras Verticais",
      },
    },
  };

  return (
    <div style={{ width: "600px", height: "400px", margin: "auto" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default MultiAxis;
