import React, { useState, useEffect } from "react";
import axios from "axios";
import "../components/Devices.css";

const URL_DM = process.env.REACT_APP_URL_DM;

function Devices() {
  const [models, setModels] = useState([]);
  const [devices, setDevices] = useState([]);
  const [activeUserId, setActiveUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [devicesPerPage] = useState(10);
  const [showStreamModal, setShowStreamModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editDeviceData, setEditDeviceData] = useState(null);
  const [inferenceModel, setInferenceModel] = useState("master_monte_carlo");

  const [filterStatus, setFilterStatus] = useState("all"); // Estado inicial: 'all', 'online' ou 'offline'
  const [showDropdown, setShowDropdown] = useState(false); // Para controlar a exibição do dropdown

  useEffect(() => {
    const fetchActiveUserId = async () => {
      try {
        const userData = JSON.parse(sessionStorage.getItem("user"));
        if (userData) {
          setActiveUserId(userData.id_user);
        }
      } catch (error) {
        console.error("Error fetching active user ID:", error);
      }
    };

    fetchActiveUserId();
  }, []);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        if (activeUserId) {
          setLoading(true);
          let url = `${URL_DM}/api/get_devices/${activeUserId}`;

          if (filterStatus !== "all") {
            url += `?status=${filterStatus}`;
          }

          const response = await axios.get(url);
          const sortedDevices = sortDevices(response.data);
          setDevices(sortedDevices);
        }
      } catch (error) {
        console.error("Error fetching devices:", error);
        setErrorMessage("Failed to fetch devices.");
      } finally {
        setLoading(false);
      }
    };

    fetchDevices();
  }, [activeUserId, filterStatus]);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await axios.get(`${URL_DM}/api/models`);
        setModels(response.data);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, []);

  const sortDevices = (devices) => {
    const onlineDevices = devices.filter((device) => device.status === "online");
    const offlineDevices = devices.filter((device) => device.status !== "online");
    return [...onlineDevices, ...offlineDevices];
  };

  const handleStartStream = () => {
    setShowStreamModal(true);
  };

  const confirmStartStream = async () => {
    try {
      setLoading(true);
      const requests = selectedDevices.map(async (idDevice) => {
        const device = devices.find((d) => d.id_device === idDevice);
        if (!device) return; // Verificação de segurança

        const requestData = {
          id_device: idDevice,
          lat: device.lat,
          long: device.long,
          inference_model: inferenceModel,
          analytics_config: "config_none",
        };

        const response = await axios.post(`${URL_DM}/api/start_stream`, requestData);
        console.log(`Started streaming for device ${idDevice}. Response:`, response.data);
        return response.data;
      });

      const responses = await Promise.all(requests);
      console.log("All devices started streaming:", responses);

      const updatedDevices = devices.map((d) => {
        if (selectedDevices.includes(d.id_device)) {
          return { ...d, status: "streaming" };
        }
        return d;
      });
      setDevices(updatedDevices);
      setSuccessMessage("Streaming started successfully.");
    } catch (error) {
      console.error("Error starting streams:", error.response ? error.response.data : error.message);
      setErrorMessage("Failed to start streaming.");
    } finally {
      setLoading(false);
      setShowStreamModal(false);
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    setFilterStatus(value);
    setShowDropdown(false); // Fechar o dropdown ao selecionar uma opção
  };

  const handleDisconnectDevice = async () => {
    try {
      setLoading(true);
      const requests = selectedDevices.map(async (idDevice) => {
        const response = await axios.get(`${URL_DM}/api/disconnect_device/${idDevice}`);
        console.log(`Disconnected device ${idDevice}. Response:`, response.data);
        return response.data;
      });

      const responses = await Promise.all(requests);
      console.log("All devices disconnected:", responses);

      const updatedDevices = devices.map((d) => {
        if (selectedDevices.includes(d.id_device)) {
          return { ...d, status: "offline" };
        }
        return d;
      });
      setDevices(updatedDevices);
      setSuccessMessage("Devices disconnected successfully.");
    } catch (error) {
      console.error("Error disconnecting devices:", error.response ? error.response.data : error.message);
      setErrorMessage("Failed to stop streaming.");
    } finally {
      setLoading(false);
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    }
  };

  const handleEditDevice = (idDevice) => {
    const device = devices.find((d) => d.id_device === idDevice);
    if (device) {
      setEditDeviceData(device);
      setShowEditModal(true);
    }
  };

  const handleUpdateDevice = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${URL_DM}/api/update_device`, editDeviceData);
      console.log("Device updated:", response.data);

      const updatedDevices = devices.map((d) => (d.id_device === editDeviceData.id_device ? editDeviceData : d));
      setDevices(updatedDevices);
      setSuccessMessage("Device updated successfully.");
    } catch (error) {
      console.error("Error updating device:", error.response ? error.response.data : error.message);
      setErrorMessage("Failed to update device.");
    } finally {
      setLoading(false);
      setShowEditModal(false);
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    }
  };

  const handleDeleteDevice = async () => {
    try {
      setLoading(true);
      const requests = selectedDevices.map(async (idDevice) => {
        const response = await axios.get(`${URL_DM}/api/delete_device/${idDevice}`);
        console.log(`Deleted device ${idDevice}. Response:`, response.data);
        return response.data;
      });

      const responses = await Promise.all(requests);
      console.log("All devices deleted:", responses);

      const updatedDevices = devices.filter((d) => !selectedDevices.includes(d.id_device));
      setDevices(updatedDevices);
      setSuccessMessage("Devices deleted successfully.");
    } catch (error) {
      console.error("Error deleting devices:", error.response ? error.response.data : error.message);
      setErrorMessage("Failed to delete devices.");
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    }
  };

  const toggleDeviceSelection = (idDevice) => {
    if (selectedDevices.includes(idDevice)) {
      setSelectedDevices(selectedDevices.filter((deviceId) => deviceId !== idDevice));
    } else {
      setSelectedDevices([...selectedDevices, idDevice]);
    }
  };

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  const currentDevices = devices.slice(indexOfFirstDevice, indexOfLastDevice);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalDevices = devices.length;
  const hasNextPage = indexOfLastDevice < totalDevices;

  const renderActionButtons = () => {
    const onlineDevices = selectedDevices.filter((id) =>
      devices.find((d) => d.id_device === id && d.status === "online")
    ).length;
    const offlineDevices = selectedDevices.filter((id) =>
      devices.find((d) => d.id_device === id && d.status !== "online")
    ).length;

    if (selectedDevices.length === 1) {
      if (onlineDevices === 1) {
        return (
          <>
            <button className="finish_Button" onClick={handleDisconnectDevice}>
              Stop Streaming
            </button>
            <button className="edit_Button" onClick={() => handleEditDevice(selectedDevices[0])}>
              Edit
            </button>
            <button className="delete_Button" onClick={() => setShowDeleteModal(true)}>
              Delete
            </button>
          </>
        );
      } else if (offlineDevices === 1) {
        return (
          <>
            <button className="start_Button" onClick={handleStartStream}>
              Start Streaming
            </button>
            <button className="edit_Button" onClick={() => handleEditDevice(selectedDevices[0])}>
              Edit
            </button>
            <button className="delete_Button" onClick={() => setShowDeleteModal(true)}>
              Delete
            </button>
          </>
        );
      }
    }

    if (onlineDevices > 0 && offlineDevices === 0) {
      return (
        <>
          <button className="finish_Button" onClick={handleDisconnectDevice}>
            Stop Streaming
          </button>
          <button className="delete_Button" onClick={() => setShowDeleteModal(true)}>
            Delete
          </button>
        </>
      );
    } else if (offlineDevices > 0 && onlineDevices === 0) {
      return (
        <>
          <button className="start_Button" onClick={handleStartStream}>
            Start Streaming
          </button>
          <button className="delete_Button" onClick={() => setShowDeleteModal(true)}>
            Delete
          </button>
        </>
      );
    }

    // return (
    // <button className="delete_Button" onClick={() => setShowDeleteModal(true)}>Delete</button>
    // );
  };

  return (
    <div className="devices">
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}

      <div className="devices_Content">
        <button className="filter-button" onClick={toggleDropdown}>
          Filtro
          <div className={`filter-dropdown ${showDropdown ? "active" : ""}`}>
            <label>
              <input
                type="checkbox"
                value="online"
                checked={filterStatus === "online"}
                onChange={handleCheckboxChange}
              />
              Online
            </label>
            <label>
              <input
                type="checkbox"
                value="offline"
                placeholder="Offline"
                checked={filterStatus === "offline"}
                onChange={handleCheckboxChange}
              />
              Offline
            </label>
          </div>
        </button>
        <div className="devices_Actions">{selectedDevices.length > 0 && renderActionButtons()}</div>
        {loading ? (
          <div className="loading-message">Loading...</div>
        ) : (
          <>
            <table className="devices_Table">
              <thead>
                <tr>
                  <th className="devices_Selector"></th>
                  <th>Nome</th>
                  <th>Modelo</th>
                  <th>Protocol</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {currentDevices.map((device) => (
                  <tr key={device.id_device}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedDevices.includes(device.id_device)}
                        onChange={() => toggleDeviceSelection(device.id_device)}
                      />
                    </td>
                    <td>{device.device_name}</td>
                    <td>{device.device_model}</td>
                    <td>{device.protocol}</td>
                    <td className={device.status === "online" ? "status-online" : "status-offline"}>
                      {device.status === "online" ? (
                        <span className="online-text">• Device Online</span>
                      ) : device.status === "streaming" ? (
                        <span className="streaming-text">Streaming</span>
                      ) : (
                        <span className="offline-text">• Device Offline</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                  <path
                    d="M16.3334 10H4.66669M4.66669 10L10.5 15.8334M4.66669 10L10.5 4.16669"
                    stroke="#344054"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Previous
              </button>
              <button onClick={() => paginate(currentPage + 1)} disabled={!hasNextPage}>
                Next
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                  <path
                    d="M4.66669 10H16.3334M16.3334 10L10.5 4.16669M16.3334 10L10.5 15.8334"
                    stroke="#344054"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </>
        )}
      </div>
      {showStreamModal && (
        <div className="device_modal">
          <div className="modal_content">
            <h2>Select Inference Model</h2>
            <select name="inference_model" value={inferenceModel} onChange={(e) => setInferenceModel(e.target.value)}>
              {/* iterate over the models array and create an option for each model */}
              {models &&
                models.map((model) => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
            </select>
            <button className="confirm_Button" onClick={confirmStartStream}>
              Confirm
            </button>
            <button className="cancel_Button" onClick={() => setShowStreamModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <div className="device_modal">
          <div className="modal_content">
            <h2>Certeza que quer deletar esses dispositivos?</h2>
            <ul>
              {selectedDevices.map((idDevice) => {
                const device = devices.find((d) => d.id_device === idDevice);
                return <li key={idDevice}>{device?.device_name}</li>;
              })}
            </ul>
            <button className="confirm_Button" onClick={handleDeleteDevice}>
              Confirm
            </button>
            <button className="cancel_Button" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {showEditModal && editDeviceData && (
        <div className="device_modal">
          <div className="modal_content">
            <h2>Edit Device</h2>
            <div className="edit-form-container">
              <div className="edit-form-item">
                <label>Device Name</label>
                <input
                  type="text"
                  value={editDeviceData.device_name}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      device_name: e.target.value,
                    })
                  }
                  placeholder="Device Name"
                />
              </div>
              <div className="edit-form-item">
                <label>Device Model</label>
                <input
                  type="text"
                  value={editDeviceData.device_model}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      device_model: e.target.value,
                    })
                  }
                  placeholder="Device Model"
                />
              </div>

              <div className="edit-form-item">
                <label>Protocol</label>
                <input
                  type="text"
                  value={editDeviceData.protocol}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      protocol: e.target.value,
                    })
                  }
                  placeholder="Protocol"
                />
              </div>

              <div className="edit-form-item">
                <label>Status</label>
                <input
                  type="text"
                  value={editDeviceData.status}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      status: e.target.value,
                    })
                  }
                  placeholder="Status"
                />
              </div>

              <div className="edit-form-item">
                <label>RTSP URL</label>
                <input
                  type="text"
                  value={editDeviceData.url_rtsp}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      url_rtsp: e.target.value,
                    })
                  }
                  placeholder="RTSP URL"
                />
              </div>

              <div className="edit-form-item">
                <label>RTSP Admin</label>
                <input
                  type="text"
                  value={editDeviceData.rtsp_admin}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      rtsp_admin: e.target.value,
                    })
                  }
                  placeholder="RTSP Admin"
                />
              </div>

              <div className="edit-form-item">
                <label>RTSP Passphrase</label>
                <input
                  type="text"
                  value={editDeviceData.passphrase_rtsp}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      passphrase_rtsp: e.target.value,
                    })
                  }
                  placeholder="RTSP Passphrase"
                />
              </div>

              <div className="edit-form-item">
                <label>RTSP IP</label>
                <input
                  type="text"
                  value={editDeviceData.ip_rtsp}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      ip_rtsp: e.target.value,
                    })
                  }
                  placeholder="RTSP IP"
                />
              </div>

              <div className="edit-form-item">
                <label>RTSP Port</label>
                <input
                  type="number"
                  value={editDeviceData.port_rtsp}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      port_rtsp: parseInt(e.target.value, 10),
                    })
                  }
                  placeholder="RTSP Port"
                />
              </div>

              <div className="edit-form-item">
                <label>Analytics Config</label>
                <input
                  type="text"
                  value={editDeviceData.analytics_config}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      analytics_config: e.target.value,
                    })
                  }
                  placeholder="Analytics Config"
                />
              </div>

              <div className="edit-form-item">
                <label>DVR Model</label>
                <input
                  type="text"
                  value={editDeviceData.modelo_dvr}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      modelo_dvr: e.target.value,
                    })
                  }
                  placeholder="DVR Model"
                />
              </div>

              <div className="edit-form-item">
                <label>Channel</label>
                <input
                  type="text"
                  value={editDeviceData.chanel}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      chanel: e.target.value,
                    })
                  }
                  placeholder="Channel"
                />
              </div>

              <div className="edit-form-item">
                <label>Device Type</label>
                <input
                  type="text"
                  value={editDeviceData.device_type}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      device_type: e.target.value,
                    })
                  }
                  placeholder="Device Type"
                />
              </div>

              <div className="edit-form-item">
                <label>Receive IP</label>
                <input
                  type="text"
                  value={editDeviceData.ip_receive}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      ip_receive: e.target.value,
                    })
                  }
                  placeholder="Receive IP"
                />
              </div>

              <div className="edit-form-item">
                <label>Transmit IP</label>
                <input
                  type="text"
                  value={editDeviceData.ip_transmit}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      ip_transmit: e.target.value,
                    })
                  }
                  placeholder="Transmit IP"
                />
              </div>

              <div className="edit-form-item">
                <label>Receive Port</label>
                <input
                  type="number"
                  value={editDeviceData.port_receive}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      port_receive: e.target.value,
                    })
                  }
                  placeholder="Receive Port"
                />
              </div>

              <div className="edit-form-item">
                <label>Transmit Port</label>
                <input
                  type="number"
                  value={editDeviceData.port_transmit}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      port_rtsp: parseInt(e.target.value, 10),
                    })
                  }
                  placeholder="Transmit Port"
                />
              </div>

              <div className="edit-form-item">
                <label>Receive Passphrase</label>
                <input
                  type="text"
                  value={editDeviceData.passphrase_receive}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      passphrase_receive: e.target.value,
                    })
                  }
                  placeholder="Receive Passphrase"
                />
              </div>

              <div className="edit-form-item">
                <label>Transmit Passphrase</label>
                <input
                  type="text"
                  value={editDeviceData.passphrase_transmit}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      passphrase_transmit: e.target.value,
                    })
                  }
                  placeholder="Transmit Passphrase"
                />
              </div>

              <div className="edit-form-item">
                <label>Stream ID</label>
                <input
                  type="text"
                  value={editDeviceData.streamid}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      streamid: e.target.value,
                    })
                  }
                  placeholder="Stream ID"
                />
              </div>

              <div className="edit-form-item">
                <label>Latitude</label>
                <input
                  type="text"
                  value={editDeviceData.lat}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      lat: Number(e.target.value),
                    })
                  }
                  placeholder="Latitude"
                />
              </div>

              <div className="edit-form-item">
                <label>Longitude</label>
                <input
                  type="text"
                  value={editDeviceData.long}
                  onChange={(e) =>
                    setEditDeviceData({
                      ...editDeviceData,
                      long: Number(e.target.value),
                    })
                  }
                  placeholder="Longitude"
                />
              </div>
            </div>

            <div className="edit-button-container">
              <button className="confirm_Button" onClick={handleUpdateDevice}>
                Update
              </button>
              <button className="cancel_Button" onClick={() => setShowEditModal(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Devices;
