import { WebRTCAdaptor } from "https://cdn.skypack.dev/@antmedia/webrtc_adaptor@SNAPSHOT";
import axios from "axios";

const URL_ANTMEDIA = process.env.REACT_APP_URL_ANTMEDIA;
const URL_ANTMEDIA_WS = process.env.REACT_APP_URL_ANTMEDIA_WS;
const URL_DM = process.env.REACT_APP_URL_DM;

export const publishCamera = (device, webRTCAdaptors) => {
  const adaptor = webRTCAdaptors.current[device.streamid];
  
  if (adaptor) {
    adaptor.publish(device.streamid);
    console.log(`Publishing camera with id: ${device.streamid}`);
  } else {
    console.error(`No WebRTCAdaptor found for id: ${device.streamid}`);
  }
};

export const addCamera = async (
  device,
  webRTCAdaptors,
  setCameras,
  criarElementoDeVideo,
  retryCameras = [],
  retryDelay = 10000
) => {
  const checkAndAddCamera = async (device) => {
    try {
      const antMediaObj = await axios.get(`${URL_ANTMEDIA}/WebRTCAppEE/rest/v2/broadcasts/${device.streamid}`);

      if (antMediaObj.status === 200 && antMediaObj.data.status === "broadcasting") {
        const videoContainer = criarElementoDeVideo(device.streamid);

        if (videoContainer instanceof HTMLDivElement) {
          if (!webRTCAdaptors.current[device.streamid]) {
            webRTCAdaptors.current[device.streamid] = new WebRTCAdaptor({
              websocket_url: `${URL_ANTMEDIA_WS}/WebRTCAppEE/websocket`,
              remoteVideoElement: videoContainer.querySelector("video"),
              mediaConstraints: {
                audio: false,
                video: {
                  width: { ideal: 1280 },
                  height: { ideal: 720 },
                  frameRate: { ideal: 30, max: 30 }
                }
              },
              callback: (info, obj) => {
                if (info === "initialized") {
                  webRTCAdaptors.current[device.streamid].play(device.streamid);
                }
                if (info === "play_started") {
                  setCameras((prevCameras) => [
                    ...prevCameras,
                    {
                      id: device.streamid,
                      id_device: device.id_device,
                      device_model: device.device_model,
                      device_name: device.device_name,
                      lat: device.lat,
                      long: device.long,
                      status: device.status,
                      videoContainer,
                    },
                  ]);
                  const video = videoContainer.querySelector("video");
                  video.addEventListener("canplay", () => {
                    video.play().catch((error) => {
                      console.error("Autoplay error: ", error);
                    });
                  });
                }
                if (info === "offline") {
                  console.log(`Stream is offline for ${device.streamid}. Publishing...`);
                  publishCamera({ id: device.streamid }, webRTCAdaptors);
                }
              },
              callbackError: (error) => {
                console.error("error callback: " + JSON.stringify(error));
                if (error === "no_stream_exist") {
                  console.log(`Stream not found for ${device.streamid}`);
                  delete webRTCAdaptors.current[device.streamid];
                }
              },
            });
          } else {
            console.log(`WebRTCAdaptor already exists for ${device.streamid}`);
          }
        } else {
          console.error("Failed to create video container:", videoContainer);
        }
      } else {
        console.log(`Stream is not broadcasting for ${device.streamid}. Attempting to start...`);
        try {
          await axios.post(`${URL_DM}/api/force_start_stream/${device.streamid}`);
          console.log(`Stream for ${device.streamid} started successfully.`);
        } catch (error) {
          console.error(`Failed to start stream for ${device.streamid}:`, error);
        }
        retryCameras.push(device);
      }
    } catch (error) {
      console.error("Error checking camera status", error);
      retryCameras.push(device);
    }
  };

  await checkAndAddCamera(device);

  if (retryCameras.length > 0) {
    setTimeout(async () => {
      const retryList = [...retryCameras];
      retryCameras.length = 0;
      for (const camera of retryList) {
        await checkAndAddCamera(camera);
      }
    }, retryDelay);
  }
};
