import React, { useState } from "react";
import "./Conveniencia.css";
import Visitantes from "./Conveniencia/Visitantes";
import TempoMedioFila from "./Conveniencia/TempoMedioFila";
import UsoDoCelular from "./Conveniencia/UsoDoCelular";

const VisitantesClientes = () => (
  <div>
    <Visitantes />
  </div>
);
const TempoMedioEspera = () => (
  <div>
    <TempoMedioFila />
  </div>
);
const UsoCelular = () => (
  <div>
    <UsoDoCelular />
  </div>
);

const Conveniencia = () => {
  const [activeComponent, setActiveComponent] = useState(0);

  const components = [
    <VisitantesClientes />,
    <TempoMedioEspera />,
    <UsoCelular />,
  ];

  const handleComponentChange = (index) => {
    setActiveComponent(index);
  };

  return (
    <div style={{ marginBottom: "2.5rem" }}>
      <div className="conv_header">
        <div
          className="conv_item"
          onClick={() => handleComponentChange(0)}
          style={{ color: activeComponent === 0 ? "#1D7EFF" : "white" }}
        >
          Visitantes x Clientes
        </div>
        <div>|</div>
        <div
          className="conv_item"
          onClick={() => handleComponentChange(1)}
          style={{ color: activeComponent === 1 ? "#1D7EFF" : "white" }}
        >
          Tempo médio de espera na fila
        </div>
        <div>|</div>
        <div
          className="conv_item"
          onClick={() => handleComponentChange(2)}
          style={{ color: activeComponent === 2 ? "#1D7EFF" : "white" }}
        >
          Uso do celular
        </div>
      </div>
      <div className="conv_body">{components[activeComponent]}</div>
    </div>
  );
};

export default Conveniencia;
