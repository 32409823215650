import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const Medicare = () => {
  const data = {
    labels: ["Pessoas", "Pacientes", "Ambulância", "Maca", "Cadeira de Rodas"],
    datasets: [
      {
        label: "Categorias",
        data: [40, 25, 15, 10, 10], // Valores estáticos para cada categoria
        backgroundColor: [
          "rgba(54, 162, 235, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(255, 99, 132, 0.6)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "right",
        labels: {
          padding: 15,
        },
      },
      title: {
        display: false,
        text: "Distribuição de Categorias",
      },
    },
  };

  return (
    <div
      style={{
        width: "400px",
        height: "400px",
        margin: "auto",
        position: "relative",
        backgroundColor: "transparent",
      }}
    >
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default Medicare;
