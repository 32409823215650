import React from "react";
import AlertList from "../components/Pages/Dashboard/AlertList/AlertList";
import styles from "./DashboardScreen.module.css";
import Sofia from "../components/Pages/Dashboard/Sofia/Sofia";
import UserCard from "../components/Pages/Dashboard/UserCard/Usercard";
import PlanCard from "../components/Pages/Dashboard/PlanCard/PlanCard";
import CameraStatusCard from "../components/Pages/Dashboard/CameraStatusCard/CameraStatusCard";
import DevicesList from "../components/Pages/Dashboard/DevicesList/DevicesList";
import InferencesQuantity from "../components/Pages/Dashboard/Inferences/InfererencesQuantity/InfererencesQuantity";
import InferencesIdentifier from "../components/Pages/Dashboard/Inferences/InfererencesIdentifier/InfererencesIdentifier";

function DashboardScreen() {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.contentColumn}>
        <div className={styles.container}>
          <div className={styles.line}>
            <div className={styles.column}>
              <div className={styles.subColumn}>
                <UserCard />
              </div>
              <div className={styles.subColumn}>
                <Sofia />
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.subColumn}>
                <PlanCard />
              </div>
              <div className={styles.subColumn}>
                <CameraStatusCard />
              </div>
            </div>
          </div>
          <div className={styles.line}>
            <div className={styles.column}>
              <InferencesIdentifier />
            </div>
            <div className={styles.column}>
              <InferencesQuantity />
            </div>
          </div>
          <div className={styles.lastLine}>
            <div className={styles.column}>
              <DevicesList />
            </div>
            <div className={styles.column}>
              <AlertList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardScreen;
