import React, { useState } from "react";
import "./PisoDeVenda.css";
import Abastecimento from "./Conveniencia/Abastecimento";
import TempoAbastecimento from "./Conveniencia/TempoAbastecimento";

const VisitantesClientes = () => <div><Abastecimento /></div>;
const TempoMedioEspera = () => (
  <div><TempoAbastecimento /></div>
);

const PisoDeVenda = () => {
  const [activeComponent, setActiveComponent] = useState(0);

  const components = [
    <VisitantesClientes />,
    <TempoMedioEspera />,
  ];

  const handleComponentChange = (index) => {
    setActiveComponent(index);
  };

  return (
    <div style={{ marginBottom: "2.5rem" }}>
      <div className="conv_header">
        <div
          className="conv_item"
          onClick={() => handleComponentChange(0)}
          style={{ color: activeComponent === 0 ? "#1D7EFF" : "white" }}
        >
          Carros por Bomba
        </div>
        <div>|</div>
        <div
          className="conv_item"
          onClick={() => handleComponentChange(1)}
          style={{ color: activeComponent === 1 ? "#1D7EFF" : "white" }}
        >
          Tempo médio de abastecimento
        </div>
      </div>
      <div className="conv_body">{components[activeComponent]}</div>
    </div>
  );
};

export default PisoDeVenda;
