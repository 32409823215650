import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../components/Register.css";

const URL_DM = process.env.REACT_APP_URL_DM;

function Register() {
  const [user_name, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false); // Set success as boolean
  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const validatePassword = (password) => {
    const passwordPolicy = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPolicy.test(password);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match. Please try again.");
      return;
    }

    if (!validatePassword(password)) {
      setError(
        "Password must be at least 8 characters long, and include:\n" +
          "- At least one uppercase letter\n" +
          "- At least one lowercase letter\n" +
          "- At least one digit\n" +
          "- At least one special character"
      );
      return;
    }

    try {
      const response = await axios.post(`${URL_DM}/api/register_user`, {
        user_name: user_name,
        email: email,
        password: password,
        access_level: "1",
      });

      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        setError(response.data.response);
      }
    } catch (error) {
      setError(error.response?.data?.response || "An error occurred during registration. Please try again.");
    }
  };

  return (
    <div className="register">
      <div className="register_Content">
        <div className="register_Form">
          <form onSubmit={handleSubmit} name='register'>
            <h2>Sign Up</h2>
            <label htmlFor="username">Username*</label>
            <input
              type="text"
              placeholder="Enter your Username"
              id="username"
              value={user_name}
              onChange={handleUsernameChange}
              required
            />
            <label htmlFor="email">E-mail*</label>
            <input
              type="email"
              placeholder="Enter your E-mail"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <label htmlFor="password">Password*</label>
            <input
              type="password"
              placeholder="Enter your password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <label htmlFor="confirmPassword">Confirm Password*</label>
            <input
              type="password"
              placeholder="Confirm your password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
            <button type="submit">Sign Up</button>
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">Registration successful! Redirecting to login...</p>}
          </form>
          <p>
            Already have an account?{" "}
            <Link className="login_Link" to="/">
              Log in
            </Link>
          </p>
        </div>
        <div className="register_Banner">
          <h1>Sky has no limits</h1>
        </div>
      </div>
      <div className="register_Footer">
        <div className="register_Footer_Content">
          <div className="register_Copy">
            <p>© SpySkyTech 2024</p>
          </div>
          <div className="register_Contact">
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.33334 2.66666L6.77662 6.47695C7.2174 6.7855 7.43779 6.93977 7.67751 6.99953C7.88927 7.05231 8.11074 7.05231 8.32249 6.99953C8.56222 6.93977 8.78261 6.7855 9.22339 6.47695L14.6667 2.66666M4.53334 11.3333H11.4667C12.5868 11.3333 13.1468 11.3333 13.5747 11.1153C13.951 10.9236 14.2569 10.6176 14.4487 10.2413C14.6667 9.81348 14.6667 9.25343 14.6667 8.13332V3.86666C14.6667 2.74655 14.6667 2.1865 14.4487 1.75868C14.2569 1.38235 13.951 1.07639 13.5747 0.884643C13.1468 0.666656 12.5868 0.666656 11.4667 0.666656H4.53334C3.41323 0.666656 2.85318 0.666656 2.42535 0.884643C2.04903 1.07639 1.74307 1.38235 1.55132 1.75868C1.33334 2.1865 1.33334 2.74655 1.33334 3.86666V8.13332C1.33334 9.25343 1.33334 9.81348 1.55132 10.2413C1.74307 10.6176 2.04903 10.9236 2.42535 11.1153C2.85318 11.3333 3.41323 11.3333 4.53334 11.3333Z"
                stroke="#667085"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p>contact@spyskytech.com</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
