import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./UserCard.module.css";

const UserCard = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [, setIdUser] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    const fetchUserDataFromSession = () => {
      try {
        const userData = JSON.parse(sessionStorage.getItem("user"));
        if (userData) {
          setIdUser(userData.id_user);
          setEmail(userData.email);
          setFirstName(userData.user_name);
          setLastName(userData.last_name || "");
          setProfileImage(userData.user_image);
        } else {
          console.error("User data not found in session.");
        }
      } catch (error) {
        console.error("Error fetching user data from session:", error);
      }
    };

    fetchUserDataFromSession();
  }, []);

  return (
    <div className={styles.card}>
      {profileImage ? (
        <img src={profileImage} alt={`${firstName} avatar`} className={styles.avatar} />
      ) : (
        <svg width="22" height="22" viewBox="0 0 22 22">
          {/* SVG content */}
        </svg>
      )}

      <h2 className={styles.username}>
        {firstName} {lastName}
      </h2>
      <p className={styles.email}>{email}</p>
      <div className={styles.buttonContainer}>
        <Link to="/profile" className={styles.button}>
          Editar Perfil
        </Link>
      </div>
    </div>
  );
};

export default UserCard;
