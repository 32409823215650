import HeatMapTempoCelular from "./HeatMaps/HeatMapTempoCelular/HeatMapTempoCelular";
import HeatMapVisitantes from "./HeatMaps/HeatMapVisitantes/HeatMapVisitantes";

const dayVisitors = Math.floor(Math.random() * (4000- 100 + 1)) + 100;
const dayCallers = Math.floor(Math.random() * (20 - 3 + 1) + 1);

const diasDaSemana = [
  "Domingo",
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
];
const meses = [
  "janeiro",
  "fevereiro",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro",
];

const dataAtual = new Date();
const diaSemana = diasDaSemana[dataAtual.getDay()];
const dia = dataAtual.getDate();
const mes = meses[dataAtual.getMonth()];
const ano = dataAtual.getFullYear();

const dataFormatada = `${diaSemana}, ${dia} de ${mes} de ${ano}`;

const TempoAbastecimento = () => {
  return (
      <div className="vis_container" >
        <div className="date_picker">
          <div>{dataFormatada}</div>
        </div>
        <div className="vis_content">
          <div className="vis_left_content">
            <div className="vis_header">
              <div className="vis_title">Total de abastecimentos</div>
              <div className="vis_display_tv">{dayVisitors}</div>
            </div>
            <div className="vis_content_data">
              <HeatMapVisitantes />
            </div>
          </div>
          <div className="vis_left_content">
            <div className="vis_header">
              <div className="vis_title">Tempo médio de abastecimento</div>
              <div className="vis_display_tc">{dayCallers} minutos</div>
            </div>
            <div className="vis_content_data2">
              <HeatMapTempoCelular />
            </div>
          </div>
        </div>
      </div>
  );
};

export default TempoAbastecimento;
