import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./CameraStatusCard.module.css";
import ShowAlerts from "../../../../utils/ShowAlerts/ShowAlerts";

const URL_DM = process.env.REACT_APP_URL_DM;

const CameraStatusCard = () => {
  const [user, setUser] = useState({});
  const [onlineCount, setOnlineCount] = useState(0);
  const [offlineCount, setOfflineCount] = useState(0);
  const [allDevicesOffline, setAllDevicesOffline] = useState(false);

  useEffect(() => {
    const storedUser = JSON.parse(sessionStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  useEffect(() => {
    const fetchDevices = async () => {
      if (!user.id_user) return;
      try {
        const response = await axios.get(`${URL_DM}/api/get_devices/${user.id_user}`);
        const devices = response.data;

        const online = devices.filter((device) => device.status === "online").length;
        const offline = devices.length - online;

        setOnlineCount(online);
        setOfflineCount(offline);
        setAllDevicesOffline(online === 0);
      } catch (error) {
        if (error.response.data.response === "No devices found!") {
          setAllDevicesOffline(true);
        }
        console.log(error);
      }
    };

    fetchDevices();
  }, [user]);

  return (
    <div className={styles.card}>
      <div className={styles.cameraBox}>
        <div className={styles.textLeft}>{!allDevicesOffline ? onlineCount : '0'}</div>
        <div className={styles.textRight}>Câmeras Online</div>
      </div>
      <div className={styles.offlineBox}>
        <div className={styles.textLeft}>{offlineCount}</div>
        <div className={styles.textRight}>Câmeras Offline</div>
      </div>
      <div className={styles.streamingBox}>
        <div className={styles.textLeft}><ShowAlerts /></div>
        <div className={styles.textRight}>Alertas recebidos</div>
      </div>
    </div>
  );
};

export default CameraStatusCard;
