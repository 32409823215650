import React, { useState, useEffect } from "react";
import axios from "axios";

const URL_BIFROST = process.env.REACT_APP_URL_BIFROST;

function ShowAlerts() {
  const [alertCount, setAlertCount] = useState(0);
  const [userId, setUserId] = useState("");
  const [isUserIdLoaded, setIsUserIdLoaded] = useState(false);

  useEffect(() => {
    const fetchUserDataFromSession = () => {
      try {
        const userData = JSON.parse(sessionStorage.getItem("user"));
        if (userData) {
          setUserId(userData.id_user);
          setIsUserIdLoaded(true);
        } else {
          console.error("User data not found in session.");
        }
      } catch (error) {
        console.error("Error fetching user data from session:", error);
      }
    };
    fetchUserDataFromSession();
  }, []);

  useEffect(() => {
    const fetchAlerts = async () => {
      if (!isUserIdLoaded) return;

      try {
        const requestUrl = `${URL_BIFROST}/historical_alerts/${userId}`;
        const response = await axios.get(requestUrl);
        setAlertCount(response.data.data.length);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAlerts();
  }, [isUserIdLoaded, userId]);

  return <>{alertCount}</>;
}

export default ShowAlerts;
