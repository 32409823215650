import React from 'react';
import "../components/iFrameComponent.css"

const URL_METABASE = process.env.REACT_APP_URL_METABASE;

function IframeComponent() {
  return (
    <iframe className='iframe'
      src={`${URL_METABASE}/public/dashboard/7fb6855f-9c7e-45ac-ad95-b5c77eba4238`}
      allowTransparency="true"
      title="AI Dashboard"
    ></iframe>
  );
}

export default IframeComponent;
