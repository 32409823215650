import React, { useState, useEffect } from "react";
import "./App.css";
import "../src/theme/variables.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import HomeScreen from "./screens/HomeScreen";
import DevicesScreen from "./screens/DevicesScreen";
import CamerasScreen from "./screens/CamerasScreen";
import SettingsScreen from "./screens/SettingsScreen";
import ViewerScreen from "./screens/ViewerScreen";
import AiScreen from "./screens/AiScreen";
import DashboardScreen from "./screens/DashboardScreen";
import { MessageProvider } from "./context/MessageContext";
import ReportsScreen from "./screens/ReportsScreen";
import Navbar from "./components/Navbar";

function App() {
  const [user, setUser] = useState("");

  useEffect(() => {
    setUser(JSON.parse(sessionStorage.getItem("user")));
  }, []);

  return (
    <Router>
      <div className="App">
        {!user ? null : (
          <div className="AppNav">
            <Navbar />
          </div>
        )}
        <div className="AppContent">
          <MessageProvider>
            <Routes>
              {!user ? (
                <>
                  <Route path="/" element={<LoginScreen />} />
                  <Route path="/home" element={<LoginScreen />} />
                  <Route path="/profile" element={<LoginScreen />} />
                  <Route path="/cameras" element={<LoginScreen />} />
                  <Route path="/devices" element={<LoginScreen />} />
                  <Route path="/reports" element={<LoginScreen />} />
                  <Route path="/ai" element={<LoginScreen />} />
                  <Route path="/dashboard" element={<LoginScreen />} />
                  <Route path="/viewer" element={<LoginScreen />} />
                </>
              ) : (
                <>
                  <Route path="/cameras" element={<CamerasScreen />} />
                  <Route path="/profile" element={<SettingsScreen />} />
                  <Route path="/devices" element={<DevicesScreen />} />
                  <Route path="/reports" element={<ReportsScreen />} />
                  <Route path="/register" element={<RegisterScreen />} />
                  <Route path="/home" element={<HomeScreen />} />
                  <Route path="/ai" element={<AiScreen />} />
                </>
              )}

              <Route path="/viewer" element={<ViewerScreen />} />

              <Route path="/dashboard" element={<DashboardScreen />} />
            </Routes>
          </MessageProvider>
        </div>
      </div>
    </Router>
  );
}

export default App;
